import React, { Component, useEffect, useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import { appLayoutLabels } from '../../common/labels';
import {Box, Grid, ColumnLayout, Header, Table, SpaceBetween, Container,} from '@amzn/awsui-components-react/polaris';
import Button from "@amzn/awsui-components-react/polaris/button";
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { States } from "../../common/States";
import { Report, ReportItem, ReportSchedule, FondueApi } from '../../fondue-api/generated-src';
import ReportResultDateSelection from './ReportResultDateSelection';
import FondueApiFactory from "../../fondue-api/FondueApiFactory";
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import * as constants from '../../common/constants';
import { useData } from '../DataContext';
export default function ReportResultView() {
    Object.freeze(Object.prototype);
    const { report_id } = useParams<{report_id: string}>();
    const [report, setReport] = useState<Report>();
    const [reportName, setReportName] = useState("");
    const [state, setState] = useState<States>(States.loading)
    const [flashbar, setFlashbar] = useState<FlashbarProps.MessageDefinition[]>([])
    const [initialFlashbarMessage, setInitialFlashbarMessage] = useState('');
    const {data} = useData();

    const flashbarReportLoading: FlashbarProps.MessageDefinition[] = [
        {
            type: 'info', content: 'Loading report result selection', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];
    const flashbarReportLoadingError: FlashbarProps.MessageDefinition[] = [
        {
            type: 'error', content: constants.USER_VIEW_REPORT_ACCESS_DENIED_ERROR, dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];


    async function getReport(FondueApi: FondueApi, counter: number) {
        await getMidwayJwtToken();
        if(counter < 3){
            await FondueApi.getReport(report_id)
                .then((response) =>{
                    setReport(response.data)
                    setReportName(response.data['name']);
                    setFlashbar([]);
                })
                .catch((e) => {
                    getReport(FondueApi, counter + 1)
                    setFlashbar(flashbarReportLoadingError);
                })
        }
    }

    useEffect(() => {
        (async () => {
            const FondueApi = FondueApiFactory();

            await getReport(FondueApi, 0)
            if(data) {
                if ('invokeReportExecutionStatus' in data && data['invokeReportExecutionStatus'] == 'success') {
                    setInitialFlashbarMessage(data['invokeReportExecutionMessage'])
                }
            }
        })();
    }, []);



    return (
        <AppLayout
            navigation={<ServiceNavigation />}
            notifications={<Flashbar items={flashbar} />}
            breadcrumbs={<Breadcrumbs items={[
                { text: constants.REPORTS_TABLE, href: '#/reports' },
                { text: reportName, href: '#/reports/'+ report_id + '/results' }]} />}
            content={
                <Box variant="h1">
                    {(!!report && !!reportName) &&
                    <Header
                        variant="h1"
                        actions={
                            <Button id="er" iconName="edit" href={'#/reports/'+ report_id}>Edit Report</Button>
                        }
                    >
                        {(!!report && !!reportName) && reportName + " Result" || ""}
                    </Header>
                    }
                    {<ReportResultDateSelection reportName={reportName} reportType={report?.report_type} setState={setState} setFlashbar={setFlashbar} initialFlashbarMessage={initialFlashbarMessage} setInitialFlashbarMessage={setInitialFlashbarMessage}/>}
                </Box>
            }
            contentType="table"
            ariaLabels={appLayoutLabels}
            toolsHide={true}
        />
    );
}