import React, {createContext, useContext, useState} from 'react';
interface DataContextType{
    data: {} | null;
    setData: React.Dispatch<React.SetStateAction<{} | null>>;
}
const DataContext = createContext<DataContextType | undefined>(undefined);
export const DataProvider: React.FC = ({children}) => {
    const [data, setData] = useState<{} | null>(null);
    return (
        <DataContext.Provider value={{data, setData}}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => {
    const context = useContext(DataContext);
    if(!context) {
        throw new Error('useData must be used within Data provider.')
    }
    return context;
}
