import Box from '@amzn/awsui-components-react/polaris/box';
import { RadioGroupProps } from '@amzn/awsui-components-react/polaris/radio-group';
import Link from '@amzn/awsui-components-react/polaris/link';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { Report } from '../../fondue-api/generated-src';
import { ReactNode } from 'react';
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Button from "@amzn/awsui-components-react/polaris/button";

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Report>[] = [
    {
        id: 'name',
        header: 'Name',
        cell: item => <Link href={"#/reports/"+ item.id + "/result"}>{item.name}</Link>,
        minWidth: '160px',
        sortingField: 'name'
    },
    {
        id: 'category',
        header: 'Category',
        cell: item => item.category || "-",
        minWidth: '140px',
        sortingField: 'category'
    },
    {
        id: 'project',
        header: 'Project',
        cell: item => item.project || "-",
        minWidth: '140px',
        sortingField: 'project'
    },
    {
        id: 'owner',
        header: 'Owner',
        cell: item => item.owner,
        minWidth: '100px',
        maxWidth: '140px',
        sortingField: 'owner'
    },
    {
        id: 'num_items',
        header: '# Items',
        cell: item => item.report_item_order.length,
        minWidth: '40px'
    },
    {
        id: 'schedule_state',
        header: 'Schedule',
        cell: item => (
            <StatusIndicator type={item.state === 'DISABLED' ? 'stopped' : 'success'}>{item.state}</StatusIndicator>
        ),
        minWidth: '160px',
        sortingField: 'state'
    },
    {
        id: 'id',
        header: 'Report ID',
        cell: item => item.id,
        minWidth: '100px',
        sortingField: 'id'
    }
];

export function getMatchesCountText(count: number) {
    return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
    title: string;
    subtitle: string;
    action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                {subtitle}
            </Box>
            {action}
        </Box>
    );
}

export const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page'
};

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [
    { value: 'table', label: 'Table' },
    { value: 'cards', label: 'Cards' }
];
