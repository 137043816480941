import { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Flashbar, {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar";
import Input from '@amzn/awsui-components-react/polaris/input';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import {Report, FondueApi, ReportItem, ReportSchedule} from '../../fondue-api/generated-src';
import { States } from "../../common/States";
import Select from "@amzn/awsui-components-react/polaris/select";
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import * as constants from '../../common/constants';

const flashbarInvalidInput: FlashbarProps.MessageDefinition[] = [
    { type: 'error', content: constants.FLASHBAR_CREATE_REPORT_INVALID_INPUT, dismissible: true }
];

const flashbarSubmittingInput: FlashbarProps.MessageDefinition[] = [
    { type: 'info', content: constants.FLASHBAR_UPDATE_REPORT_INFO, dismissible: true }
];

const flashbarItemsComplete: FlashbarProps.MessageDefinition[] = [
    { type: 'success', content: constants.FLASHBAR_REPORT_LOADED_SUCCESS, dismissible: true }
];

const flashbarReportError: FlashbarProps.MessageDefinition[] = [
    { type: 'error', content: constants.FLASHBAR_UPDATE_REPORT_ERROR, dismissible: true }
];

const flashbarScheduleError: FlashbarProps.MessageDefinition[] = [
    { type: 'error', content: constants.FLASHBAR_UPDATE_REPORT_SCHEDULE_ERROR, dismissible: true }
];


export interface EditReportFormProps {
    report: Report;
    reportSchedule: ReportSchedule;
    setState: (state: States) => void;
    setFlashbar: (x: FlashbarProps.MessageDefinition[]) => void;
}

export default function ReportsEditFormPage({report, reportSchedule, setState, setFlashbar}: EditReportFormProps) {
    Object.freeze(Object.prototype);

    const { report_id }= useParams<{report_id: string}>();
    const history = useHistory();

    const [reportName, setReportName] = useState(report.name)
    const [reportOwner, setReportOwner] = useState( report.owner)
    const [reportCategory, setReportCategory] = useState(report.category || '')
    const [reportProject, setReportProject] = useState(report.project || '')
    const [reportItemOrder, setReportItemOrder] = useState<string []>(report.report_item_order || [])

    const [reportState, setReportState] = useState(reportSchedule.State || '')
    const [reportScheduleExpression, setReportScheduleExpression] = useState(reportSchedule.ScheduleExpression || "");

    const [disableSubmit, setDisableSubmit] = useState(false);

    // Defaulting schedule field to disabled for Fondue Phase 2 October 2022
    const [disableScheduleField, setDisableScheduleField] = useState(true);


    const stateOptions = {
        'ENABLED': { "value": "ENABLED", "label": "ENABLED" },
        'DISABLED': { "value": "DISABLED", "label": "DISABLED"}
    }

    const [selectionOption, setSelectionOption] = useState(stateOptions[reportSchedule.State!] || {});


    async function disableReportSchedule(FondueApi: FondueApi) {
        await getMidwayJwtToken();
        await FondueApi.disableReportSchedule(report_id)
            .then((response) =>{
                history.push('/reports/' + report_id)
            })
            .catch((e) => {
                setState(States.error);
            })
    }

    async function updateReportSchedule(FondueApi: FondueApi) {
        await getMidwayJwtToken();
        const updatedReportSchedule: ReportSchedule = {
            report_id: report_id,
            name: report_id,
            ScheduleExpression: reportScheduleExpression,
            State: reportState
        }

        await FondueApi.updateReportSchedule(report_id, updatedReportSchedule)
            .then((response) => {
                history.push('/reports/' + report_id)
            })
            .catch((e) => {
                setState(States.error);
                setFlashbar(flashbarScheduleError);
                setDisableSubmit(false);
                return;
            })
    }

    async function updateReport(FondueApi: FondueApi) {
        await getMidwayJwtToken();
        const updatedReport: Report = {
            id: report_id,
            name: reportName,
            owner: reportOwner,
            state: reportState,
            category: reportCategory,
            project: reportProject,
            report_item_order: reportItemOrder,
            bindle: report.bindle,
            report_type: report.report_type
        };
        await FondueApi.updateReport(report_id, updatedReport)
            .then((response) => {
            })
            .catch((e) => {
                setState(States.error);
                setFlashbar(flashbarReportError);
                setDisableSubmit(false);
                return;
            })
    }

    async function editReport() {
        await getMidwayJwtToken();
        if (!reportName || !reportOwner) {
            setFlashbar(flashbarInvalidInput)
            return;
        }
        const FondueApi = FondueApiFactory();

        // Disabling submit button and setting Flashbar to submitting
        setDisableSubmit(true);
        setFlashbar(flashbarSubmittingInput);

        // Updating report
        await updateReport(FondueApi);

        // Disabling or updating report schedule
        if(reportState === "DISABLED"){
            await disableReportSchedule(FondueApi);
        }
        else{
            await updateReportSchedule(FondueApi);
        }

    }

    function setStateOption(event){
        setSelectionOption(event.detail.selectedOption);
        setReportState(event.detail.selectedOption.value);

        // Disabling editing CRON Schedule Option for initial Phase 2 Delivery October 2022
        // if(event.detail.selectedOption.value === 'ENABLED'){
        //     setDisableScheduleField(false);
        // }
        // else{
        //     setDisableScheduleField(true);
        // }
    }

    useEffect(() => {
        (async () => {
            setFlashbar(flashbarItemsComplete);

            // Disabling editing CRON Schedule Option for initial Phase 2 Delivery October 2022
            // if(reportState === "DISABLED"){
            //     setDisableScheduleField(true)
            // }
            // else{
            //     setDisableScheduleField(false);
            // }

        })();
    }, [setReportState, setDisableScheduleField]);


    return (
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button id="cancel" variant="link" href={'#/reports/'+ report_id}>
                        Cancel
                    </Button>
                    <Button id="submit" variant="primary" onClick={editReport} disabled={disableSubmit}>
                        Submit
                    </Button>
                </SpaceBetween>
            }
        >
            <FormField
                id="name"
                label="Report Name"
                description="Name of report">
                <Input
                    value = {reportName}
                    onChange={event =>
                        setReportName(event.detail.value)}
                />
            </FormField>
            <FormField
                id="owner"
                label="Report Owner"
                description="Business owner of report">
                <Input
                    value = {reportOwner}
                    onChange={event =>
                        setReportOwner(event.detail.value)
                    }
                />
            </FormField>
            <FormField
                id="category"
                description="Category of report"
                label={
                    <span>
                      Report Category <i> - optional</i>{" "}
                    </span>
                }
            >
                <Input
                    value={reportCategory}
                    onChange={event =>
                        setReportCategory(event.detail.value)
                    }
                />
            </FormField>
            <FormField
                id="project"
                description="Project type of report"
                label={
                    <span>
                      Report Project <i> - optional</i>{" "}
                    </span>
                }
            >
                <Input
                    value={reportProject}
                    onChange={event =>
                        setReportProject(event.detail.value)
                    }
                />
            </FormField>
            <FormField
                id="state"
                description="State of report"
                label="Report state"
            >
                <Select
                    selectedOption={selectionOption}
                    placeholder="State of Report"
                    options={[
                        stateOptions['ENABLED'],
                        stateOptions['DISABLED']
                    ]}
                    onChange={event => {
                        setStateOption(event);
                    }}
                />
            </FormField>
            <FormField
                id="schedule"
                description="Schedule of report generation. Defaulted to Monday mornings"
                label={
                    <span>
                      Report Schedule <i> - optional</i>{" "}
                    </span>
                }
            >
                <Input
                    value={reportScheduleExpression}
                    disabled={disableScheduleField}
                    onChange={event =>
                        setReportScheduleExpression(event.detail.value)
                    }
                />
            </FormField>
        </Form>
    );

}
