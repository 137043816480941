import { Component } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import ToolsPanel from './ToolsPanel';
import DetailsTable from './Table';
import { appLayoutLabels } from '../../common/labels';
import './styles.scss';
import * as constants from '../../common/constants';

const flashbarItems: FlashbarProps.MessageDefinition[] = [
  { type: 'success', content: constants.FLASHBAR_RESOURCE_CREATION_SUCCESS, dismissible: true }
];

// Class TableView is a skeleton of a Table using AWS-UI React components.
export default class TableView extends Component {
  render() {
    return (
      <AppLayout
        navigation={<ServiceNavigation />}
        // flash notification for demo purposes
        notifications={<Flashbar items={flashbarItems} />}
        breadcrumbs={<Breadcrumbs items={[{ text: 'Table demo', href: '' }]} />}
        content={<DetailsTable />}
        contentType="table"
        toolsOpen={false}
        tools={<ToolsPanel />}
        ariaLabels={appLayoutLabels}
      />
    );
  }
}
