import { Component, useEffect, useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import ToolsPanel from './ToolsPanel';
import ReportsTable from './ReportsTable';
import { appLayoutLabels } from '../../common/labels';
import './styles.scss';
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import { States } from "../../common/States";
import * as constants from '../../common/constants';

const flashbarItemsLoading: FlashbarProps.MessageDefinition[] = [
    { type: 'info', content: constants.FLASHBAR_REPORTS_LOADING_INFO, dismissible: true }
];

const flashbarItemsComplete: FlashbarProps.MessageDefinition[] = [
    { type: 'success', content: constants.FLASHBAR_REPORTS_LOADING_SUCCESS, dismissible: true }
];

const flashbarItemsFailed: FlashbarProps.MessageDefinition[] = [
    { type: 'error', content: constants.FLASHBAR_REPORTS_LOADING_FAILED_ERROR, dismissible: true }
];

const flashbarItemsForbidden: FlashbarProps.MessageDefinition[] = [
    // TODO: Add a link for bizmet team information so users can more easily address this issue
    //       Also maybe show bindle lock to show whether a user should have access or not
    { type: 'error', content: constants.FLASHBAR_FONDUE_FORBIDDEN_WARNING, dismissible: true }
];



export default function ReportsTableView() {

    const [state, setState] = useState<States>(States.loading)

    const [flashbar, setFlashbar] = useState<FlashbarProps.MessageDefinition[]>(flashbarItemsLoading)


    useEffect(() => {
        if(state === States.loading){
            setFlashbar(flashbarItemsLoading)
        }
        else if(state === States.success){
            setFlashbar(flashbarItemsComplete)
        }
        else if(state === States.forbidden){
            setFlashbar(flashbarItemsForbidden)
        }
        else{
            setFlashbar(flashbarItemsFailed)
        }
    }, [state, flashbar]);


    return (
        <AppLayout
            navigation={<ServiceNavigation />}
            notifications={<Flashbar items={flashbar} />}
            breadcrumbs={<Breadcrumbs items={[{ text: constants.REPORTS_TABLE, href: '' }]} />}
            content={<ReportsTable setState={setState} state={state} />}
            contentType="table"
            toolsHide={true}
            tools={<ToolsPanel />}
            ariaLabels={appLayoutLabels}
        />
    );
}
