import {useCallback, useEffect, useRef, useState} from 'react';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import { Report, ReportItem, FondueApi } from '../../fondue-api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {Box, ButtonDropdown, ColumnLayout, Container, Grid} from "@amzn/awsui-components-react/polaris";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Textarea from '@amzn/awsui-components-react/polaris/textarea';
import Input from "@amzn/awsui-components-react/polaris/input";
import Select from '@amzn/awsui-components-react/polaris/select';
import { useSelect, useMultiselect, useInput } from '../hooks';
import { States } from "../../common/States";
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import Checkbox from '@amzn/awsui-components-react/polaris/checkbox';
import { GetReportItemTypes, IsDerReport } from '../../common/ReportTypes';
import DerReportItemForm from './custom/DerReportItemForm';
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Table from "@amzn/awsui-components-react/polaris/table";
import {DateUtil} from "../../util/DateUtil";
import {Util} from "../../util/Util";
import RunQueryComponent from "./RunQueryComponent";
import * as constants from '../../common/constants';

export interface EditMetricFormProps {
    setState: (state: States) => void;
    report: Report;
    reportItem: ReportItem | null;
    setAddDisabled: (x: boolean) => void;
    setEditDisabled: (x: boolean) => void;
    setAddEditInfoDisabled: (x: boolean) => void;
    setRefreshTable: (refresh: boolean) => void;
}

export default function({setState, report, reportItem, setAddDisabled, setEditDisabled, setAddEditInfoDisabled, setRefreshTable}: EditMetricFormProps) {
  Object.freeze(Object.prototype);
  const [itemID, setItemID] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemQuery, setItemQuery] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemFormat, setItemFormat] = useState("");
  const [selectionOption, setSelectionOption] = useState({ "value": reportItem ? reportItem!.type : ""})
  const [disableQuery, setDisableQuery] = useState(false);
  const [disableFormat, setDisableFormat] = useState(false);
  const [formButtonsDisabled, setFormButtonsDisabled] = useState(false);
  const [wowEnabled, setWowEnabled] = useState(false);
  const [disableWow, setDisableWow] = useState(false);
  const [wowEnabledString, setWowEnabledString] = useState('false');

  const itemTypeOptions = GetReportItemTypes(report)

  /**Returns if should allow empty sql query */
  function AllowEmptySqlQuery(): boolean {
    return IsDerReport(report); // Allow for IAM DER
  }

  async function saveReportItem(reportItemConfig?: {}) {
    await getMidwayJwtToken();
    const itemType = selectionOption['value'];
    // If Item is of type header and name not provided, setState to invalid input
    if(itemType === 'header' && itemName.trim() === ""){
        setState(States.invalid)
        return;
    }
    // If Item is of type query and query or name is not provided, setState to invalid input
    if(!AllowEmptySqlQuery() && itemType !== 'header' && (itemQuery.trim() === "" || itemName.trim() ==="")){
        setState(States.invalid)
        return;
    }

    // If Item is of type query_multiple_result and no format is provided, setState to invalid input
    if(itemType === 'query_multiple_result' && itemFormat.trim() === ""){
        setState(States.invalid)
        return;
    }

    const FondueApi = FondueApiFactory();
    if (reportItem){
        // Update reportItem object
        reportItem.id = itemID;
        reportItem.description = itemDescription;
        reportItem.name = itemName;
        reportItem.type = selectionOption['value'];
        reportItem.query = itemQuery;
        reportItem.format = itemFormat;
        reportItem.report_item_config =  {
            ...reportItemConfig,
            wow: wowEnabledString
        }

        // Add query and/or format for query item
        if(itemType !== 'header'){
            reportItem['query'] = itemQuery;

            // Add format for multiple result type
            if(itemType === 'query_multiple_result'){
                reportItem['format'] = itemFormat;
            }
        }

        setState(States.updatingReportItem);
        setFormButtonsDisabled(true);

        // Calling updateReportItem
        await FondueApi.updateReportItem(reportItem.id, reportItem)
            .then((response) => {
                setState(States.updateReportItemSuccess);
                setFormButtonsDisabled(false);
                setRefreshTable(true);

                // Closing Modal after submission of update report item
                setAddEditInfoDisabled(false);
                setAddDisabled(true);
                setEditDisabled(true);
            })
            .catch((e)=> {
                if (e.response.status === 404){
                    setFormButtonsDisabled(false);
                    setState(States.updateReportItemConsoleDatashareDeny);
                }
                else{
                    setFormButtonsDisabled(false);
                    setRefreshTable(true);

                    // Closing Modal after submission of update report item
                    setAddEditInfoDisabled(false);
                    setAddDisabled(true);
                    setEditDisabled(true);
                }
        });

    }
  }

  function updateFormOnItemTypeSelect(itemType){
    setSelectionOption(itemTypeOptions[itemType]);
    if(itemType === 'header') {
      setDisableQuery(true);
      setDisableFormat(true);
      setDisableWow(true);
    }
    // Disable format field for query_single_result
    else {
      setDisableQuery(false);
      if(itemType === 'query_multiple_result'){
          setDisableFormat(false);
          setDisableWow(true);
      }
      else{
          setDisableFormat(true);
          setDisableWow(false);
      }
    }
  }

    function updateWowEnabledString(wowEnabled){
        if(wowEnabled === true) {
            setWowEnabledString('true')
            setWowEnabled(true)
        }
        else {
            setWowEnabledString('false')
            setWowEnabled(false)
        }
    }

  function cancelAddItem() {
    // Clear Form
    setItemName('');
    setItemID('');
    setItemQuery('');
    updateFormOnItemTypeSelect('');
    setItemDescription('');
    setWowEnabled(false);
    // Show info
    setAddEditInfoDisabled(false);
    setAddDisabled(true);
    setEditDisabled(true);
  }

    function getReportItem(){
        const reportItem: ReportItem = {
            id: itemID,
            name: itemName,
            type: selectionOption.value,
            report_id: report.id,
            bindle: report.bindle,
            report_item_config: {
                wow: wowEnabledString
            },
            description: itemDescription,
            query: itemQuery,
            format: itemFormat
        };

        return reportItem;
    }

  function resetFields() {
    if (reportItem){
        setItemName(reportItem.name);
        setItemID(reportItem.id);
        setItemQuery((reportItem.query) ? (reportItem.query) : (''));
        const currentItemType = itemTypeOptions[(reportItem) ? (reportItem.type) : ('')];
        if (currentItemType){
            updateFormOnItemTypeSelect(currentItemType.value);
        }
        setItemDescription(reportItem.description ? (reportItem.description) : (''));
        setWowEnabled(!!(reportItem.report_item_config && reportItem.report_item_config.wow === 'true'));
    }
  }


  useEffect(() => {
    setItemName((reportItem) ? (reportItem.name) : (''));
    setItemID((reportItem) ? (reportItem.id) : (''));
    setItemQuery((reportItem && reportItem.query) ? (reportItem.query) : (''));
    const currentItemType = itemTypeOptions[(reportItem) ? (reportItem.type) : ('')];
    if (currentItemType){
      updateFormOnItemTypeSelect(currentItemType.value);
      if(currentItemType.value == 'query_multiple_result'){
        setItemFormat((reportItem && reportItem.format) ? (reportItem.format) : (''));
      }
    }
    setItemDescription((reportItem && reportItem.description) ? (reportItem.description) : (''));
    setWowEnabled(!!(reportItem && reportItem.report_item_config && reportItem.report_item_config.wow === 'true'));
  }, [reportItem]);

  return (
    <Container
            header={
                <Header variant="h2" description="">
                    Edit Item
                </Header>
            }
        >
            {
                IsDerReport(report)
                ? <DerReportItemForm clearFields={cancelAddItem} createReportItem={saveReportItem} disabled={formButtonsDisabled} isEditing={true} resetFields={resetFields}
                                    itemDescriptionState={[itemDescription, setItemDescription]}
                                    itemNameState={[itemName, setItemName]}
                                    itemQueryState={[itemQuery, setItemQuery]}
                                    reportItem={reportItem} 
                                    metricTypeState={[selectionOption, (event) => {}]}
                                    itemTypeOptions={itemTypeOptions}/>
                :<Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button id="cancel" variant="normal" onClick={cancelAddItem} disabled={formButtonsDisabled}>
                                {constants.CANCEL}
                            </Button>
                            <Button id="reset" variant="normal" onClick={resetFields} disabled={formButtonsDisabled}>
                                {constants.RESET}
                            </Button>
                            <Button id="submit" variant="primary" onClick={() => saveReportItem()} disabled={formButtonsDisabled}>
                                {constants.SAVE}
                            </Button>
                        </SpaceBetween>
                    }
                >
                <SpaceBetween size="m">
                <FormField
                        id='item_id'
                        label={
                            <span>
                                Item ID
                            </span>
                        }
                        description={itemID}
                />
                    <FormField
                        id='metrictype'
                        label={constants.EDIT_REPORT_ITEM_TYPE_LABEL}>
                        <Select selectedOption={selectionOption}
                                placeholder="Select metric type"
                                options={Object.keys(itemTypeOptions).map((itemType) => { return itemTypeOptions[itemType] })}
                                onChange={event => {
                                    updateFormOnItemTypeSelect(event.detail.selectedOption.value);
                                }}/>
                    </FormField>
                    <FormField
                        id='editname'
                        label={constants.EDIT_REPORT_ITEM_NAME_LABEL}>
                        <Input
                            value = {itemName}
                            onChange={(event) =>
                                setItemName(event.detail.value)
                            }
                        />
                    </FormField>
                    <FormField
                        id='description'
                        label={
                            <span>
                                {constants.EDIT_REPORT_ITEM_DESCRIPTION_LABEL} <i> - optional </i>{" "}
                            </span>
                        }
                    >
                        <Textarea
                            value={itemDescription}
                            rows={3}
                            onChange={({detail}) =>
                                setItemDescription(detail.value)}
                        />
                    </FormField>
                    <FormField
                        id='query'
                        label={
                            <span>
                                {constants.EDIT_REPORT_ITEM_SQL_QUERY_LABEL}  <i> - required for query type </i>{" "}
                            </span>
                        }
                        description= {constants.EDIT_REPORT_ITEM_SQL_QUERY_DESCRIPTION}
                    >
                        <Textarea
                            disabled={disableQuery}
                            value={itemQuery}
                            rows={15}
                            onChange={({detail}) =>
                                setItemQuery(detail.value)}
                            placeholder={constants.EDIT_REPORT_ITEM_SQL_QUERY_PLACEHOLDER}
                        />
                    </FormField>
                    <FormField
                        id='format'
                        label={
                            <span>
                                {constants.EDIT_REPORT_ITEM_QUERY_FORMAT} <i> - required for query_multiple_result </i>{" "}
                            </span>
                        }
                        description= {constants.EDIT_REPORT_ITEM_QUERY_FORMAT_DESCRIPTION}
                    >
                        <Input
                            disabled={disableFormat}
                            value = {itemFormat}
                            onChange={event =>
                                setItemFormat(event.detail.value)}
                            placeholder={"{account} - {count}"}
                        />
                    </FormField>
                    <FormField
                        id = 'additionalmetriccomponents'
                        label={
                            <span>
                                {constants.EDIT_REPORT_ITEM_ADDITIONAL_ITEMS_COMPONENTS_LABEL}
                            </span>
                        }
                        description={constants.EDIT_REPORT_ITEM_ADDITIONAL_ITEMS_COMPONENTS_DESCRIPTION}
                    >
                        <ColumnLayout columns={2} variant='default'>
                            <SpaceBetween size="xs">
                                <Checkbox
                                    id='wowcheckbox'
                                    disabled={disableWow}
                                    onChange={({ detail }) =>
                                    updateWowEnabledString(detail.checked)
                                    }
                                    checked={wowEnabled}
                                    >
                                    wow
                                </Checkbox>
                            </SpaceBetween>
                        </ColumnLayout>
                    </FormField>

                    <RunQueryComponent reportItem={getReportItem()}/>

                    </SpaceBetween>
                </Form>
            }
        </Container>
  );
};
