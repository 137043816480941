import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import {
    CollectionPreferences,
    Table,
    Button,
    ExpandableSection,
    Pagination,
    TextFilter
} from '@amzn/awsui-components-react/polaris';
import {ReportItem } from '../../fondue-api/generated-src';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {COLUMN_DEFINITIONS, getMatchesCountText, EmptyState, paginationLabels } from './table-config';
import React from 'react';
interface SelectedReportItemsInterface {
    reportItems: ReportItem[],
    updateSelectedReportItems: Dispatch<SetStateAction<ReportItem[]>>
}
export default function SelectedReportItemsTable({reportItems, updateSelectedReportItems}: SelectedReportItemsInterface) {
    const [selectedReportItems, setSelectedReportItems] = useState<ReportItem[]>([]);
    const [pageSize, setPageSize] = useState(10);
    const [expanded, setExpanded] = useState(true);
    const { items,  filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        reportItems || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No records"
                        subtitle="No records to display."
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can't find a match."
                    />
                )
            },
            pagination: {
                pageSize: pageSize
            },
            sorting: { defaultState: { sortingColumn: { sortingField: "metric_number" } } },
            selection: {}
        }
    );
    function onExpandedChange({detail: expanded}) {
        setExpanded(expanded.expanded)
    }
    function handleSelect(detail) {
        setSelectedReportItems(detail.selectedItems);
    }
    function handleRemove() {
        if(selectedReportItems.length > 0){
            updateSelectedReportItems(reportItems.filter(item => !(selectedReportItems.indexOf(item)!= -1)))
            setSelectedReportItems([])
        }
    }
    return (
        <ExpandableSection 
            disableContentPaddings = {true}
            variant='container'
            headerActions={
                <Button onClick={() => handleRemove()} disabled={selectedReportItems.length == 0}>
                    Remove Selection
                </Button>
            }
            headerCounter= {
                reportItems && selectedReportItems!.length ? `(${selectedReportItems!.length}/${reportItems.length})` : `(${reportItems.length})`
            }
            headerText= { 
                'Selected Items '
            }
            onChange={onExpandedChange}
            expanded={expanded}
        >
                <Table 
                    loading = {!reportItems}
                    items={items}
                    selectionType="multi"
                    selectedItems={selectedReportItems}
                    loadingText='Loading Instances'
                    columnDefinitions={COLUMN_DEFINITIONS}
                    resizableColumns
                    stickyHeader
                    onSelectionChange={({ detail }) => handleSelect(detail)}
                    pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}
                        onChange={ event  => {
                            //handlePagination(event)
                            paginationProps.onChange(event);
                        }}
                    />}
                    filter={
                        <TextFilter
                            {...filterProps}
                            countText={getMatchesCountText(filteredItemsCount!)}
                            filteringAriaLabel="Filter records"
                            filteringPlaceholder='Find Metrics'
                        />
                    }
                    preferences={
                        <CollectionPreferences
                            title="Preferences"
                            confirmLabel="Confirm"
                            cancelLabel="Cancel"
                            preferences={{
                                pageSize: pageSize,
                            }}
                            pageSizePreference={{
                                title: "Select page size",
                                    options: [
                                    { value: 10, label: "10 items"},
                                    { value: 20, label: "20 items" },
                                    { value: 50, label: "50 items" },
                                    { value: 100, label: "100 items" }
                                ]
                            }}
                            onConfirm={(event) => setPageSize(event.detail.pageSize as number)}
                        />
                    }
                />
        </ExpandableSection>
    )
} 