import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import {Report, ReportItem, FondueApi,ReportExecutionsRequest, DisableReportItemRequest} from '../../fondue-api/generated-src';
import {RadioGroup, DatePicker, ButtonDropdown, ExpandableSection, TextContent} from '@amzn/awsui-components-react/polaris';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import {States} from "../../common/States";
import {useData} from '../DataContext';
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import FondueApiFactory from "../../fondue-api/FondueApiFactory";
import {COLUMN_DEFINITIONS, getMatchesCountText, EmptyState, paginationLabels } from './table-config';
import SelectedReportItemsTable from './SelectedReportItemsTable';
import {
    Box,
    CollectionPreferences,
    FormField,
    Header,
    Table,
    Tiles,
    Container,
    ColumnLayout,
    SpaceBetween,
    Button,
    Spinner,
    Alert
} from '@amzn/awsui-components-react/polaris';
import {DateUtil} from "../../util/DateUtil"
import {Util} from "../../util/Util";
import React from 'react';
import { NUMBER_OF_WEEKS_IN_THE_DROPDOWN_LIST } from '../../common/constants';
export default function RunReportViewComponent({report_id, reportName, setReportName, state, setState}) {
    const history = useHistory();
    const {setData} = useData();
    const allItemsRadioOptionValue = 'allItems'
    const specificItemsRadioOptionValue = 'specificItems'
    const [allItems, setAllItems] = useState<ReportItem[]>();
    const [availableDatesList, setAvailableDatesList] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showWBRDatesNAAlert, setShowWBRDatesNAAlert] = useState(false);
    const [showDatesNAMessage, setShowDatesNAMessage] = useState({'header': '', 'message':''})
    const [report, setReport] = useState<Report>();
    const [reportType, setReportType] = useState('');
    const [selectedReportItems, setSelectedReportItems] = useState<ReportItem[]>([]);
    const [runReportRadioOption, setrunReportRadioOption] = useState("lastWeek");
    const [runReportModalButtonLoading, setRunReportModalButtonLoading] = useState(false)
    const [customDateRangeComponentsDisabled, setCustomDateRangeComponentsDisabled] = useState(true);
    const [runReportItemsRadioOption, setrunReportItemsRadioOption] = useState(allItemsRadioOptionValue);
    const [reportExecutionDate, setReportExecutionDate] = useState(Util.dateToYMDString(DateUtil.getLastSundayDate()));
    const [previousWeeksToRunDropdownLabel, setPreviousWeeksToRunDropdownLabel] = useState("")
    const [previousWeeksToRunDropdownDate, setPreviousWeeksToRunDropdownDate] = useState("")
    const [minBackfillDate, setMinBackfillDate] = useState('');
    const [maxBackfillDate, setMaxBackfillDate] = useState('');
    const [datesFetchingSpinner, setDatesFetchingSpinner] = useState(false)
    const [noPreviousExecutionDates, setNoPreviousExecutionDates] = useState(false)
    const [dateSelectionOptions, setDateSelectionOptions] = useState([
        {
            value: "lastWeek",
            label: "Last week only",
            description: Util.dateToYMDSlashString(DateUtil.getLastSundayDate()),
            disabled: false
        },
        {
            value: "last10Weeks",
            label: "Last 10 weeks",
            description: getLast10WeeksWBRRangeText(),
            disabled: false
        },
        {
            value: "customDateRange",
            label: "Custom date range",
            disabled: false
        }
    ])
    const numWeeksInDropdown = NUMBER_OF_WEEKS_IN_THE_DROPDOWN_LIST
    var lastNWeekDropdownItems = DateUtil.dateListToNumberedItemDictionary(DateUtil.getLastNSundayDatesFromGivenSunday(reportExecutionDate, numWeeksInDropdown))
    const [filteredWeeksDropDownItems, setFilteredWeeksDropDownItems] = useState([])
    async function getReport(FondueApi: FondueApi, counter: number) {
        await getMidwayJwtToken();
        if(counter < 3){
            await FondueApi.getReport(report_id)
                .then((response) =>{
                    setReport(response.data)
                    setReportName(response.data['name']);
                    setReportType(response.data['report_type'] || "")
                })
                .catch((e) => {
                    getReport(FondueApi, counter + 1)
                })
        }
    }
    useEffect(() => {
        (async () => {
            const FondueApi = FondueApiFactory();
            await getReport(FondueApi, 0);
        })();
    }, [setReport]);
    useEffect(() => {
        if(reportName != ''){
            // Initialize Datepicker
            handleDatePickerClick(reportExecutionDate)
        }
    }, [reportName]);
    useEffect( ()=> {
        
    }, [availableDatesList, setAvailableDatesList])
    useEffect(() => {
        (async () => {
            const FondueApi = FondueApiFactory();
            // Call listReportsMetrics which will retry up to 3 times
            await listReportMetrics(FondueApi, 0)
        })();
    }, [setAllItems, FondueApiFactory]);
    useEffect(() => {
        ( async () => {
            if(runReportItemsRadioOption == allItemsRadioOptionValue){
                setShowWBRDatesNAAlert(false)
                setReportExecutionDate(Util.dateToYMDString(DateUtil.getLastSundayDate()))
                handleDatePickerClick(Util.dateToYMDString(DateUtil.getLastSundayDate()))
                unhideAllDateSelections()
            }
            else if (runReportItemsRadioOption == specificItemsRadioOptionValue && minBackfillDate == '' && maxBackfillDate == '' && noPreviousExecutionDates == false){
                const FondueApi = FondueApiFactory();
                await getMinMaxBackfillWBRDates(FondueApi, 0)
            }
            else if(runReportItemsRadioOption == specificItemsRadioOptionValue && noPreviousExecutionDates == true) {
                hideAllDatesSelections()
            }
            else if(runReportItemsRadioOption == specificItemsRadioOptionValue && minBackfillDate.length > 2 && maxBackfillDate.length > 2) {
                setDateRangesForRerun()
            }
            else if(runReportItemsRadioOption == specificItemsRadioOptionValue && minBackfillDate == 'NA' && maxBackfillDate == 'NA') {
                hideAllDatesSelections()
            }
        })();
    }, [runReportItemsRadioOption, setrunReportItemsRadioOption, FondueApiFactory]);
    useEffect(() => {
        setDateRangesForRerun()
    }, [minBackfillDate, maxBackfillDate])
    function check10WeeksDateAvailability() {
        const dates_list = DateUtil.getLast10SundayDates()
        for(var wbr_date of dates_list) {
            if (wbr_date in availableDatesList === false){
                return false
            }
        }
        return true
    }
    function setDateRangesForRerun() {
        if (minBackfillDate.length > 2 && maxBackfillDate.length > 2) {
            setReportExecutionDate(maxBackfillDate)
            var dates_list = DateUtil.dateListToNumberedItemDictionary(DateUtil.getSundayDateRangesInYMDSlashStringFormat(minBackfillDate,maxBackfillDate).reverse())
            dates_list = dates_list.splice(0,numWeeksInDropdown)
            setFilteredWeeksDropDownItems(dates_list)
            if (dates_list.length > 0){
                setPreviousWeeksToRunDropdownLabel(dates_list[0]['text'])
                setPreviousWeeksToRunDropdownDate(dates_list[0]['id'])
            }
            var last10SundayDates = DateUtil.getLast10SundayDates()
            var lastSundayDate =  last10SundayDates[0]
            var lastTenthSundayDate = last10SundayDates[last10SundayDates.length-1]
            if (Date.parse(maxBackfillDate) < Date.parse(lastSundayDate)) {
                hideWeekOnlyDateOption();
                hideTenWeeksDateOption();
                setrunReportRadioOption('customDateRange')
                setCustomDateRangeComponentsDisabled(false)
            }
            else if(check10WeeksDateAvailability() == false){
                hideTenWeeksDateOption();
                setrunReportRadioOption('lastWeek');
            }  
        }
    }
    function hideTenWeeksDateOption() {
        var radioItems = dateSelectionOptions.map( (item) => {
            if(item.value == 'last10Weeks') {
                item.disabled = true
            }
            return item
        })
        setDateSelectionOptions(radioItems)
    }
    function unhideAllDateSelections() {
        var radioItems = dateSelectionOptions.map((item) => {
            item.disabled = false
            return item
        })
        setDateSelectionOptions(radioItems)
    }
    function hideAllDatesSelections() {
        var radioItems = dateSelectionOptions.map((item) => {
            item.disabled = true
            return item
        })
        setDateSelectionOptions(radioItems)
    }
    function hideWeekOnlyDateOption() {
        var radioItems = dateSelectionOptions.map(item => {
            if(item.value == 'lastWeek') {
                item.disabled = true
            }
            return item
        })
        setDateSelectionOptions(radioItems)
    }
    async function getMinMaxBackfillWBRDates(FondueApi: FondueApi, counter: number) {
        hideAllDatesSelections()
        setDatesFetchingSpinner(true)
        await getMidwayJwtToken();
        await FondueApi.pastAvailableReportDateRange(report_id)
        .then(async(response) => {
            if(response.status == 200) {
                const available_dates_list = response?.data?.available_dates
                if (available_dates_list == undefined) {
                    hideAllDatesSelections()
                    setMinBackfillDate('NA')
                    setMaxBackfillDate('NA')
                }
                else if(Object.keys(available_dates_list).length == 0) {
                    setNoPreviousExecutionDates(true)
                    hideAllDatesSelections()
                    setMinBackfillDate('')
                    setMaxBackfillDate('')
                }
                else {
                    unhideAllDateSelections()
                    setAvailableDatesList(available_dates_list)
                    const minDate = Object.keys(available_dates_list).reduce((min,date) => date < min ? date : min,  Object.keys(available_dates_list)[0]);
                    const maxDate = Object.keys(available_dates_list).reduce((max,date) => date > max ? date : max,  Object.keys(available_dates_list)[0]);
                    setMinBackfillDate(minDate)
                    setMaxBackfillDate(maxDate)
                }
                
            }
            setDatesFetchingSpinner(false)
        })
        .catch((e) => {
            setState(States.error)
            setMinBackfillDate('NA')
            setMaxBackfillDate('NA')
            setDatesFetchingSpinner(false)
        })
    }
    const { items,  filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        allItems || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No records"
                        subtitle="No records to display."
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can't find a match."
                    />
                )
            },
            pagination: {
                pageSize: pageSize
            },
            sorting: { defaultState: { sortingColumn: { sortingField: "metric_number" } } },
            selection: {}
        }
    );
    async function listReportMetrics(FondueApi: FondueApi, counter: number) {
        await getMidwayJwtToken();
        if(counter < 3){
            await FondueApi.listReportMetrics(report_id)
                .then(async(response) => {
                    setSelectedReportItems([]);
                    setAllItems(response.data['results'])
                    
                    setState(States.success)
                    var items:  number[] = []
                    response.data['results'].forEach(function(item){
                        if(item.metric_number)
                            items.push(item.metric_number);
                     })
                })
                .catch((e) => {
                    setState(States.error)
                    listReportMetrics(FondueApi, counter + 1)
                })
        }
    }
    function alertMessageComponent(header, message) {
        if (message && header) {
            return <Alert type="error" header={header}>
                { message }
            </Alert>
        }
        else {
            return <Alert type="error" header="Run Report failed">
                        Cannot run report. There is an existing pending or running execution for the same date range. To run the report, cancel the overlapping execution and try again or choose a different date range to run the report for.
                   </Alert>
        }
    }
    function handleCancelRunReport(){
        history.push(`/reports/${report_id}/result`)
    }
    function handleSelect(detail) {
        setSelectedReportItems(detail.selectedItems);
    }
    function handlePagination(event) {
        setSelectedReportItems([]);
    }
    function getLast10WeeksWBRRangeText(){
        let wbr_range = DateUtil.getLast10SundayDates()
        return DateUtil.convertHyphenDateToSlashDate(wbr_range[9]) + ' to ' + DateUtil.convertHyphenDateToSlashDate(wbr_range[0])
    }
    useEffect( ()=> {
        handleDateDropdownClick(DateUtil.convertHyphenDateToSlashDate(reportExecutionDate))
    }, [filteredWeeksDropDownItems, setFilteredWeeksDropDownItems])
    function handleDatePickerClick(selectedDate){
        // selectedDate is in YYYY-MM-DD format
        setReportExecutionDate(selectedDate)
        if(runReportItemsRadioOption == allItemsRadioOptionValue){
            lastNWeekDropdownItems = DateUtil.dateListToNumberedItemDictionary(DateUtil.getLastNSundayDatesFromGivenSunday(selectedDate, numWeeksInDropdown))
            handleDateDropdownClick(DateUtil.convertHyphenDateToSlashDate(selectedDate))
        }
        else {
            const dates_list = DateUtil.dateListToNumberedItemDictionary(DateUtil.getSundayDateRangesInYMDSlashStringFormat(minBackfillDate,selectedDate).reverse())
            setFilteredWeeksDropDownItems(dates_list)
        }
    }
    async function enqueueReportExecution(FondueApi: FondueApi, reportExecutionsRequest: ReportExecutionsRequest) {
        setRunReportModalButtonLoading(true)
        await getMidwayJwtToken();

        await FondueApi.enqueueReportExecution(report_id, reportExecutionsRequest)
            .then((response) => {
                setRunReportModalButtonLoading(false);
                var wbr_date_range = reportExecutionsRequest.wbr_date_range;
                var message = `Successfully initiated report to run for ${wbr_date_range[0]}`
                if(reportExecutionsRequest.wbr_date_range.length > 1){
                    message += ` to ${wbr_date_range[wbr_date_range.length - 1]}`
                }
                setData({invokeReportExecutionStatus : 'success', invokeReportExecutionMessage: message});
                setShowWBRDatesNAAlert(false)
                setShowErrorAlert(false)
                setRunReportModalButtonLoading(false)
                history.push(`/reports/${report_id}/result`);
            })
            .catch((e) => {
                // return appropriate error message to user about which WBR date runs conflict this request
                // Same 'todo' has been added in backend API function as well
                if(e.response !== undefined && e.response?.status == 500) {
                    setShowErrorAlert(true)
                }
                else if (e.response!== undefined && e.response?.data?.message?.failure_type == 'no_wbr_dates_available' ){
                    setShowWBRDatesNAAlert(true)
                    setShowDatesNAMessage({'header': 'Some Dates were not available', 'message': e.response.data.message.message })
                }
                setRunReportModalButtonLoading(false)
            })
    }
    function enqueueReportExecutionLastWeekOnly(backfill){
        const FondueApi = FondueApiFactory();
        var selected_item_ids:string[] = []
        selectedReportItems.map(item => {
            selected_item_ids.push(item.id);
        })
        const reportExecutionsRequest: ReportExecutionsRequest = {
            wbr_date_range: [Util.dateToYMDString(DateUtil.getLastSundayDate())],
            backfill: backfill,
            selected_report_items: selected_item_ids
        }
        enqueueReportExecution(FondueApi, reportExecutionsRequest);
    }

    function enqueueReportExecutionLast10Weeks(backfill){
        const FondueApi = FondueApiFactory();
        var selected_item_ids:string[] = []
        selectedReportItems.map(item => {
            selected_item_ids.push(item.id);
        })
        const reportExecutionsRequest: ReportExecutionsRequest = {
            wbr_date_range: DateUtil.getLast10SundayDates(),
            backfill: backfill,
            selected_report_items: selected_item_ids
        }
        enqueueReportExecution(FondueApi, reportExecutionsRequest);
    }

    function enqueueReportExecutionCustomDateRange(backfill){
        const FondueApi = FondueApiFactory();
        var selected_item_ids:string[] = []
        selectedReportItems.map(item => {
            selected_item_ids.push(item.id);
        })
        let startDate = DateUtil.convertSlashDateToHyphenDate(previousWeeksToRunDropdownDate)
        let endDate = reportExecutionDate

        const reportExecutionsRequest: ReportExecutionsRequest = {
            wbr_date_range: DateUtil.getSundayDateRange(startDate, endDate),
            backfill: backfill,
            selected_report_items: selected_item_ids
        }
        enqueueReportExecution(FondueApi, reportExecutionsRequest);
    }
    function submitRunReportRequest(backfill) {
        switch (runReportRadioOption) {
            
            case 'lastWeek':
                enqueueReportExecutionLastWeekOnly(backfill);
                break;
            case 'last10Weeks':
                enqueueReportExecutionLast10Weeks(backfill);
                break;
            case 'customDateRange':
                enqueueReportExecutionCustomDateRange(backfill);
                break;
        }
    }
    function runReportModalOptionClick(value){
        setrunReportRadioOption(value)
        switch (value) {
            case 'lastWeek':
                setCustomDateRangeComponentsDisabled(true);
                break;
            case 'last10Weeks':
                setCustomDateRangeComponentsDisabled(true);
                break;
            case 'customDateRange':
                setCustomDateRangeComponentsDisabled(false);
                break;
        }
    }
    function handleDateDropdownClick(dateID){
        if (runReportItemsRadioOption == allItemsRadioOptionValue) {
            lastNWeekDropdownItems.forEach( (element) => {
                if (element['id'] === dateID){
                    setPreviousWeeksToRunDropdownLabel(element['text'])
                    setPreviousWeeksToRunDropdownDate(dateID)
                }
            });
        }
        else {
            if (availableDatesList) {
                var missing_dates: string[] = []
                for(var item of filteredWeeksDropDownItems) {
                    var item_date:string = item['id']
                    if(Date.parse(item['id']) >= Date.parse(dateID) && !(Util.replaceCharacter(item_date, "/", "-") in availableDatesList) ) {
                        missing_dates.push(Util.replaceCharacter(item_date, "/", "-"))
                    }
                }
                if(missing_dates.length > 0) {
                    setShowWBRDatesNAAlert(true)
                    if (missing_dates.length == 1) {
                        setShowDatesNAMessage({'header': 'Some Dates were not available', 'message': 'No Previous successful execution found for : ['+ missing_dates + ']. Please run the report for all items for the missing date and try again.' })
                    }
                    else {
                        setShowDatesNAMessage({'header': 'Some Dates were not available', 'message': 'No Previous successful executions found for : ['+ missing_dates.join(", ") + ']. Please run the report for all items for the missing dates and try again.' })
                    }
                }
                else {
                    setShowWBRDatesNAAlert(false)
                }
            }
            filteredWeeksDropDownItems.forEach( (element) => {
                if (element['id'] === dateID){
                    setPreviousWeeksToRunDropdownLabel(element['text'])
                    setPreviousWeeksToRunDropdownDate(dateID)
                }
            })
        }
    }
    return (
        <div>
            { 
                showErrorAlert ? alertMessageComponent('','') : ''
            }
            {
                showWBRDatesNAAlert ? <Alert type="error" header="Missing Dates for Rerun Alert">
                    <ExpandableSection headerText='Please expand to see the missing dates within the given date range'>
                        {showDatesNAMessage['message']}
                    </ExpandableSection>
                </Alert>: ''
            }
            {
                noPreviousExecutionDates && runReportItemsRadioOption==specificItemsRadioOptionValue ? alertMessageComponent('Rerun Report Alert', 'No Previous Executions found for Rerun. Please Run Report for All Items.') : ''
            }
            {
                minBackfillDate=='NA' && maxBackfillDate=='NA' ? alertMessageComponent('Dates Not available for rerun.', "We weren't able to fetch previous report dates. Reload the page or try again later.") : ''
            }
            <Box variant="h1">
            {(!!report && !!reportName) &&
                <Header
                    variant="h1"
                >
                    {(!!report && !!reportName) && reportName || ""}
                </Header>
            }
            </Box>
            <div>
                <Container
                    disableContentPaddings
                    header={
                        <Header
                            variant="h2"
                        >
                        { 'Run options' }
                        </Header>
                    }
                    footer={
                        <ColumnLayout columns={2} variant='default'>
                            <div>
                            <FormField label='Report Timeframe' description='Select the timeframe to run the report. Date availability is dependent on available previous run history.'>
                                <RadioGroup
                                        onChange={({detail}) => runReportModalOptionClick(detail.value)}
                                        value={runReportRadioOption}
                                        items={dateSelectionOptions}
                                    />
                                {
                                    customDateRangeComponentsDisabled ? '' : (
                                            runReportItemsRadioOption == allItemsRadioOptionValue || (runReportItemsRadioOption == specificItemsRadioOptionValue && minBackfillDate.length>2 && maxBackfillDate.length>2) ?
                                            <div>
                                            <FormField
                                                label="Ending report date"
                                            >
                                                {
                                                    runReportItemsRadioOption == allItemsRadioOptionValue ?
                                                    <DatePicker
                                                        onChange={({detail}) => handleDatePickerClick(detail.value)}
                                                        value={reportExecutionDate}
                                                        isDateEnabled={date => date.getDay() == 0 && date < new Date()}
                                                        nextMonthAriaLabel="Next month"
                                                        placeholder="YYYY/MM/DD"
                                                        previousMonthAriaLabel="Previous month"
                                                        todayAriaLabel="Today"
                                                    />: ''
                                                }
                                                {
                                                    runReportItemsRadioOption == specificItemsRadioOptionValue && minBackfillDate.length>2 && maxBackfillDate.length>2  ?
                                                    <DatePicker
                                                        onChange={({detail}) => handleDatePickerClick(detail.value)}
                                                        value={reportExecutionDate}
                                                        isDateEnabled={date => date.getDay() == 0 && date <= DateUtil.getNextDayDate(maxBackfillDate) && date >= new Date(minBackfillDate)}
                                                        nextMonthAriaLabel="Next month"
                                                        placeholder="YYYY/MM/DD"
                                                        previousMonthAriaLabel="Previous month"
                                                        todayAriaLabel="Today"
                                                    />: ''
                                                }
                                            </FormField>

                                            <FormField
                                                label="Previous weeks to run"
                                            >
                                                {
                                                    runReportItemsRadioOption == allItemsRadioOptionValue ? <ButtonDropdown
                                                        ariaLabel={previousWeeksToRunDropdownLabel}
                                                        items={lastNWeekDropdownItems}
                                                        onItemClick={(event) => handleDateDropdownClick(event.detail.id)}
                                                    >
                                                        {previousWeeksToRunDropdownLabel}
                                                    </ButtonDropdown> : ''
                                                }
                                                {
                                                    runReportItemsRadioOption == specificItemsRadioOptionValue && minBackfillDate.length>2 && maxBackfillDate.length>2  ?
                                                    <ButtonDropdown
                                                        ariaLabel={previousWeeksToRunDropdownLabel}
                                                        items={filteredWeeksDropDownItems}
                                                        onItemClick={(event) => handleDateDropdownClick(event.detail.id)}
                                                    >
                                                        {previousWeeksToRunDropdownLabel}
                                                    </ButtonDropdown> : ''
                                                }

                                            </FormField>
                                            </div>: ''
                                    )
                                }
                                {
                                    runReportItemsRadioOption == specificItemsRadioOptionValue && datesFetchingSpinner ? <Box>
                                        <Spinner /> Fetching the available date ranges.  
                                        </Box> : ''                              
                                }
                            </FormField>
                            </div>
                            
                            <div>
                                <FormField label='Run Report Type' 
                                    description='Select what report items you want to include in the report. Report must be ran with all items before specific items can be re-ran.'
                                stretch>
                                <Tiles
                                    onChange={({ detail }) => setrunReportItemsRadioOption(detail.value)}
                                    value={runReportItemsRadioOption}
                                    items={[
                                        {
                                            label: "All Items",
                                            description: "Selecting all items will run the report for all the Report Items of the report",
                                            value: "allItems"
                                        },
                                        {
                                            label: "Rerun Specific Items",
                                            description: "Selecting rerun specific items option will backfill specific metrics with the most recent report execution.",
                                            value: "specificItems"
                                        }
                                    ]}
                                    columns={1}
                                />
                                </FormField>                            
                            </div>
                        </ColumnLayout>
                    }
                >
                </Container>
            </div>
            { runReportItemsRadioOption == specificItemsRadioOptionValue && minBackfillDate.length > 2 && maxBackfillDate.length > 2 ?
                <Box margin={{top:'l', bottom:'l'}}>
                <Table
                    {...collectionProps}
                    loading={!allItems && state === States.loading}
                    loadingText="Loading instances"
                    selectionType="multi"
                    selectedItems={selectedReportItems}
                    header={
                        <Header
                            counter={
                                allItems &&
                                (selectedReportItems!.length ? `(${selectedReportItems!.length}/${allItems.length})` : `(${allItems.length})`)
                            }
                        >
                            Items
                        </Header>
                    }
                    columnDefinitions={COLUMN_DEFINITIONS}
                    resizableColumns
                    items={items}
                    isItemDisabled={item => item.type == 'header'}
                    onSelectionChange={({ detail }) => handleSelect(detail)}
                    pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}
                        onChange={ event  => {
                            paginationProps.onChange(event);
                        }}
                    />}
                    filter={
                        <TextFilter
                            {...filterProps}
                            countText={getMatchesCountText(filteredItemsCount!)}
                            filteringAriaLabel="Filter records"
                            filteringPlaceholder='Find Metrics'
                        />
                    }
                    preferences={
                        <CollectionPreferences
                            title="Preferences"
                            confirmLabel="Confirm"
                            cancelLabel="Cancel"
                            preferences={{
                                pageSize: pageSize,
                            }}
                            pageSizePreference={{
                                title: "Select page size",
                                    options: [
                                    { value: 10, label: "10 items"},
                                    { value: 20, label: "20 items" },
                                    { value: 50, label: "50 items" },
                                    { value: 100, label: "100 items" }
                                ]
                            }}
                            onConfirm={(event) => setPageSize(event.detail.pageSize as number)}
                        />
                    }
                />
                </Box> : ''
            }
            {
                runReportItemsRadioOption == specificItemsRadioOptionValue && selectedReportItems.length > 0 ? <SelectedReportItemsTable reportItems = {selectedReportItems} updateSelectedReportItems={setSelectedReportItems}/> : ''
            }
            <Box float="right" margin={{top:'l', bottom: 'l'}}>
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="normal" onClick={() => handleCancelRunReport()}>Cancel</Button>
                    {
                        runReportItemsRadioOption == allItemsRadioOptionValue ? <Button variant="primary" id="run-report-md" onClick={() => { submitRunReportRequest(false);}} loading={runReportModalButtonLoading}>Run Report</Button> : 
                        <Button variant="primary" id="run-report-md" disabled={selectedReportItems.length == 0 || showWBRDatesNAAlert} onClick={() => submitRunReportRequest(true)} loading={runReportModalButtonLoading}> Run Report </Button>
                    }
                </SpaceBetween>
            </Box>
        </div>

    )
}
