import {Util} from "./Util";

export class DateUtil {

    public static getLastSundayDate() {

        let date = new Date()

        // Most recent Sunday date
        date.setDate(date.getDate() - date.getDay())
        return date

    }
    public static getNextDayDate(input_date) {
        let date = new Date(input_date)
        date.setDate(date.getDate() + 1)
        return date
    }
    public static getLast10SundayDates() {
        let date = this.getLastSundayDate()

        // Save first Sunday date
        var tenSundays = [Util.dateToYMDString(date)]

        // Load previous 9 Sunday dates
        for (let i = 0; i < 9; i++) {

            date.setDate(date.getDate() - 7)
            tenSundays.push(Util.dateToYMDString(date))

        }

        return tenSundays
    }

    public static convertHyphenDateToSlashDate(dateString){

        return dateString.split('-').join('/')

    }

    public static convertSlashDateToHyphenDate(dateString){

        return dateString.split('/').join('-')

    }


    public static getLastNSundayDatesFromGivenSunday(sundayDate, numWeeks) {

        let localDate = new Date(sundayDate)

        let mDate = new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate())

        // Save first Sunday date
        var nSundays = [Util.dateToYMDSlashString(mDate)]

        // Reduce count of week by 1 since first week was already deduced above
        numWeeks -= 1

        // Load previous N Sunday dates
        for (let i = 0; i < numWeeks; i++) {

            mDate.setDate(mDate.getDate() - 7)
            nSundays.push(Util.dateToYMDSlashString(mDate))

        }

        return nSundays
    }

    public static dateListToNumberedItemDictionary(date_list){

        var i = 0;

        return date_list.map(function(e) {

            i++;
            return {text: `${i} ${(i < 2) ? 'week':'weeks'} (${e})`, id: e}

        })

    }
    public static getTodayDate(){
        let current_date = new Date();
        return current_date
    }

    public static dateListToItemDictionaryHyphen(date_list){

        var i = 0;
        let date_in_hyphen = '';
        return date_list.map(function(dt) {

            i++;
            date_in_hyphen = DateUtil.convertSlashDateToHyphenDate(dt)
            return {text: date_in_hyphen, id: date_in_hyphen}

        })

    }

    public static getSundayDateRange(from_sunday_date, to_sunday_date){
        let temp_date = new Date(from_sunday_date);
        let current_date = new Date(temp_date.getUTCFullYear(), temp_date.getUTCMonth(), temp_date.getUTCDate());

        temp_date = new Date(to_sunday_date);
        let end_date = new Date(temp_date.getUTCFullYear(), temp_date.getUTCMonth(), temp_date.getUTCDate());

        const date_list: string[] = []

        while(current_date <= end_date){
            date_list.push(Util.dateToYMDString(current_date))
            current_date.setDate(current_date.getDate() + 7)
        }
        return date_list
    }

    public static getSundayDateRangesInYMDSlashStringFormat(from_sunday_date, to_sunday_date){
        let temp_date = new Date(from_sunday_date);
        let current_date = new Date(temp_date.getUTCFullYear(), temp_date.getUTCMonth(), temp_date.getUTCDate());

        temp_date = new Date(to_sunday_date);
        let end_date = new Date(temp_date.getUTCFullYear(), temp_date.getUTCMonth(), temp_date.getUTCDate());

        const date_list: string[] = []

        while(current_date <= end_date){
            date_list.push(Util.dateToYMDSlashString(current_date))
            current_date.setDate(current_date.getDate() + 7)
        }
        return date_list
    }

    public static getElapsedTime(startDate, endDate){

        let seconds = Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
        if (seconds >= 60){
            let minutes = Math.floor(seconds / 60);
            return minutes + ':' + Util.prependZero(seconds - (minutes * 60), 2);
        } else {
            return '0:' + Util.prependZero(seconds, 2);
        }
    }

}
