import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import {
    Box,
    Container,
    Header,
    Table,
    SpaceBetween,
    RadioGroup,
    Spinner, Link, Checkbox} from '@amzn/awsui-components-react/polaris';
import Button from "@amzn/awsui-components-react/polaris/button";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import { useParams } from "react-router-dom";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import { ReportExecution, RetrieveReportRequest, ReportExecutionsRequest, RetrieveCFUrlRequest, 
    FondueApi, RetrieveDatadumpRequest, SubmitTaskTokenRequest, GenMVPRequest, GenMVPResponse, ReportItem } from '../../fondue-api/generated-src';
import {States} from "../../common/States";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import { IsDerReportTypeString } from "../../common/ReportTypes";
import {DateUtil} from "../../util/DateUtil"
import {Util} from "../../util/Util";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import { useCollection } from '@amzn/awsui-collection-hooks';
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import {getMatchesCountText, paginationLabels} from "../reports/table-config";
import { promptUserSimAnd2PR} from '../../common/ContingentAuth';
import { setFlashbarCustomError, setFlashbarCustomInfo } from '../../common/CustomStateParams';
import ApprovalRequestForm from './custom/AppSecApprovalRequestForm';
import * as constants from '../../common/constants';
import Confirmation from '../confirmation/Confirmation';

import {useData} from '../DataContext';
export interface ReportResultDateSelectionProps {
    reportName: string;
    reportType?: string;
    setState: (state: States) => void;
    setFlashbar: (x: FlashbarProps.MessageDefinition[]) => void;
    initialFlashbarMessage: string,
    setInitialFlashbarMessage: (string) => void;
}
export interface VisibleColumns {
    id: string;
    visible: boolean;
}
export interface ExecutionSubmission {
    report_invoke: string
}
export default function ReportResultDateSelection({reportName, reportType, setState, setFlashbar, initialFlashbarMessage, setInitialFlashbarMessage}: ReportResultDateSelectionProps) {
    Object.freeze(Object.prototype);
    const { report_id }= useParams<{report_id: string}>();
    const [startDateValue, setStartDateValue] = useState("");
    const {setData} = useData();
    const [endDateValue, setEndDateValue] = useState("");
    const [fileName, setFileName] = useState("");

    const [downloadDisabled, setDownloadDisabled] = useState(true);
    const [genReportDisabled, setGenReportDisabled] = useState(true);
    const [downloaddatadumpDisabled, setDownloadDatadumpDisabled] = useState(true);
    const [generateMVPDisabled, setGenerateMVPDisabled] = useState(true);
    const [mvpConfirmationVisible, setMvpConfirmationVisible] = useState(false);
    const [retrievePdfDisabled, setRetrievePdfDisabled] = useState(true);
    const [loadPdf, setLoadPdf] = useState(false);

    const [approvalRequestModalVisible, setApprovalRequestModalVisible] = useState(false);
    const [appsecRequestModalButtonDisabled, setAppsecRequestModalButtonDisabled] = useState(true)
    const history = useHistory();
    const [startDateDisabled, setStartDateDisabled] = useState(true);
    const [endDateDisabled, setEndDateDisabled] = useState(false);
    const [viewResultDisabled, setViewResultDisabled] = useState(true);
    const [viewResultLoading, setViewResultLoading] = useState(false);
    const [cancelDisabled, setCancelDisabled] = useState(true);
    const [runAgainDisabled, setRunAgainDisabled] = useState(true);
    const [downloadDisplayedDisabled, setDownloadaDisplayedDisabled] = useState(true);
    const [showLatestReportError, setShowLatestReportError] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false);

    const [cloudFrontUrl, setCloudFrontUrl] = useState("");
    const [pdfUrl, setPdfUrl] = useState("");

    const [runReportModalVisible, setRunReportModalVisible] = useState(false);
    const [reportExecutionDate, setReportExecutionDate] = useState(Util.dateToYMDString(DateUtil.getLastSundayDate()));

    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [runAgainModalVisible, setRunAgainModalVisible] = useState(false);
    const [runReportModalButtonLoading, setRunReportModalButtonLoading] = useState(false)

    // Alert used on page load if most recent execution is failed
    const [showErrorAlert, setShowErrorAlert] = useState(false)

    const defaultVisibleColumns = useMemo(() => ['wbr_date', 'end_datetime', 'execution_id', 'execution_status', 'items_included'].map(ele => ({'id': ele, 'visible': true})), [])

    const [allExecutions, setAllExecutions] = useState<ReportExecution[]>();
    const [selectedItems, setSelectedItems] = useState<ReportExecution[]>([]);
    const [latestExecution, setLatestExecution] = useState<ReportExecution>();
    const [selectedExecution, setSelectedExecution] = useState<ReportExecution>();
    const [displayedExecutionID, setDisplayedExecutionID] = useState("");
    const [displayedExecutionEndDate, setDisplayedExecutionEndDate] = useState("");
    const [showResultContainer, setShowResultContainer] = useState(false);
    const [visibleColumns, setVisibleColumns] = useState<VisibleColumns[]>(defaultVisibleColumns);

    const [obtainCazDisabled, setObtainCazDisabled] = useState(true);

    // TODO: Use CustomStateParams when merging into mainline
    const setFlashbarCustomSuccess: Function = customMessage => [
        {
            type: 'success', content: customMessage, dismissible: true,
            onDismiss: () => {setFlashbar([]); setData({});}
        }
    ];

    const flashbarFileNotFound: FlashbarProps.MessageDefinition[] = [
        {
            type: 'error', content: constants.FLASHBAR_FILE_NOT_FOUND_FOR_REPORT_DATE_RANGE_ERROR, dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];

    const flashbarReportRetrievingError: FlashbarProps.MessageDefinition[] = [
        {
            type: 'error', content: constants.FLASHBAR_RETRIEVING_REPORT_RESULT_ERROR, dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];


    const flashbarReportAccessDenied: FlashbarProps.MessageDefinition[] = [
        {
            type: 'error', content: constants.USER_VIEW_REPORT_ACCESS_DENIED_ERROR, dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];

    const flashbarReportDownloading: FlashbarProps.MessageDefinition[] = [
        {
            type: 'info', content: 'Downloading results', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];


    const flashbarGeneratingDerReport: FlashbarProps.MessageDefinition[] = [
        {
            type: 'info', content: constants.FLASHBAR_MANUAL_REPORT_GENERATION_INFO, dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ]

    const flashbarGeneratingDerReportError: FlashbarProps.MessageDefinition[] = [
        {
            type: 'info', content: constants.FLASHBAR_MANUAL_DER_REPORT_GENERATION_ERROR, dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];

    const flashbarRunReportSuccess: FlashbarProps.MessageDefinition[] = [
        {
            type: 'success', content: constants.FLASHBAR_SCHEDULED_REPORT_GENERATION_SUCCESS, dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];


    function clearFields(){
        setStartDateValue("");
        setEndDateValue("");
    }

    function downloadReportS3(url: string){
        const link = document.createElement('a');

        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async function retrieveReportExcel(FondueApi: FondueApi, retrieveReportRequest: RetrieveReportRequest) {
        await getMidwayJwtToken();
        await FondueApi.retrieveReportResult(report_id, retrieveReportRequest)
            .then((response) =>{
                // Trigger download of Excel in Browser if Cloudfront Signed Url present
                if(cloudFrontUrl != '') {
                    downloadReportS3(response.data.url!);
                    setState(States.success);
                }
                // If CloudFrontUrl is empty show access denied
                else{
                    setFlashbar(flashbarReportAccessDenied);
                }

            })
            .catch((e) => {
                // Unauthorized error
                if(e.response.status === 403){
                    setFlashbar(flashbarReportAccessDenied);
                }
                // File not found error
                else if(e.response.status === 404){
                    setFlashbar(flashbarFileNotFound);
                }
                else{
                    setFlashbar(flashbarReportRetrievingError);
                }

                setState(States.error);
            })
    }

    function enqueueDatadumpExecution(){
        const FondueApi = FondueApiFactory();
        const wbr_date = [Util.dateToYMDString(DateUtil.getTodayDate())]
        const reportExecutionsRequest: ReportExecutionsRequest = {
            wbr_date_range: wbr_date,
            backfill: false,
            selected_report_items: []
        }
        enqueueReportExecution(FondueApi, reportExecutionsRequest);
    }

    async function enqueueReportExecution(FondueApi: FondueApi, reportExecutionsRequest: ReportExecutionsRequest) {

        setRunReportModalButtonLoading(true)
        await getMidwayJwtToken();

        await FondueApi.enqueueReportExecution(report_id, reportExecutionsRequest)
            .then((response) => {
                closeRunReportModal()
                setRunReportModalButtonLoading(false)
                var wbr_date_range = reportExecutionsRequest.wbr_date_range;
                var message = `Successfully initiated report to run for ${wbr_date_range[0]}`
                if(reportExecutionsRequest.wbr_date_range.length > 1){
                    message += ` to ${wbr_date_range[wbr_date_range.length - 1]}`
                }
                setFlashbar(setFlashbarCustomSuccess(message));

                if(runAgainModalVisible){
                    closeRunAgainModal();
                }
            })
            .catch((e) => {
                // Todo: return appropriate error message to user about which WBR date runs conflict this request
                // Same 'todo' has been added in backend API function as well
                setShowErrorAlert(true)
                setRunReportModalButtonLoading(false)
            })
    }

    function retrieveReportExecutions(){
        const FondueApi = FondueApiFactory();
        setRefreshTable(true);
        listReportExecutions(FondueApi);

        // Disabling all action buttons as selected Execution will be cleared
        setRunAgainDisabled(true);
        setDownloadDisabled(true);
        setCancelDisabled(true);
        setAppsecRequestModalButtonDisabled(true);
    }

    async function listReportExecutions(FondueApi: FondueApi) {
        await getMidwayJwtToken();

        await FondueApi.getReportExecutions(report_id)
            .then((response) => {
                setAllExecutions(response.data['results']);
                // validate if der report type and check for app_sec status to render additional columns
                if(IsDerReportTypeString(reportType)) {
                    enableAppSecColumnsInReportExecution(response.data['results']);
                }

                setRefreshTable(false);
            })
            .catch((e) => {
                setRefreshTable(false);
                setAllExecutions([]);
            })
    }

    async function enableAppSecColumnsInReportExecution(data) {
        const appSecLinkExist = data?.some(ele => (typeof ele.app_sec_link !== 'undefined' && ele.app_sec_link))
        const appSecStatusExist = data?.some(ele => (typeof ele.app_sec_status !== 'undefined' && ele.app_sec_status))
        let columns = [...visibleColumns]
        
        if(appSecStatusExist && columns.map(e => e.id).indexOf('app_sec_status') === -1) {
            columns.push({id: 'app_sec_status', visible: true})
        }
        if(appSecLinkExist && columns.map(e => e.id).indexOf('app_sec_link') === -1) {
            columns.push({id: 'app_sec_link', visible: true})
        }

        setVisibleColumns(columns)
    }

    function closeRunReportModal(){
        // Close Modal
        setRunReportModalVisible(false)

        // Hide all messages
        setShowErrorAlert(false)
    }

    function cancelExecution(){
        const FondueApi = FondueApiFactory();

        const cancelRequest: ReportExecution = {
            report_id: report_id,
            wbr_date: selectedExecution!.wbr_date,
            execution_id: selectedExecution!.execution_id
        }

        cancelReportExecution(FondueApi, cancelRequest);
    }

    /**
     * Downloads datadump results.
     * CAZ evaluation performed in backend and starts CAZ user workflow if required.  
     *
     * @param format - format of download in either json or csv
     * @param isRetry - used to make recursive call only once after obtaining CAZ 
     *                  should only be true if called within function 
    */
	async function downloadDatadump(format="json", isRetry=false){
        if (!selectedExecution || !selectedExecution.execution_id ){
            setFlashbar(setFlashbarCustomError("Execution not selected to download datadump"));
            return;
        }

        setFlashbar(flashbarReportDownloading);
        const executionId = selectedExecution!.execution_id!;
		const FondueApi = FondueApiFactory();
		const retrieveDatadumpRequest: RetrieveDatadumpRequest = {
			report_id: report_id,
            execution_id: executionId,
			reportName: reportName,
			date: selectedExecution!.wbr_date,
            format: format
		}

        try {
            await getMidwayJwtToken();
            const response = await FondueApi.retrieveDatadumpResult(report_id, retrieveDatadumpRequest);
            downloadReportS3(response.data.url!);
            setState(States.success);
        } catch (error: any) {
            const response = error.response;
            // Only start CAZ workflow if it's the first attempt, otherwise will prompt after deny
            if (!isRetry && response.status == 403 && "caz" in response.data) {
                const caz_data = response.data.caz;
                if (caz_data.authorization_url != null) {
                    try {
                        await promptUserSimAnd2PR(caz_data.authorization_url);
                        // recursive call to try again
                        await downloadDatadump(format, true);
                    } catch {
                        setFlashbar(setFlashbarCustomError(`Failed to obtain contingent authorization for ${executionId}`,setFlashbar));
                    }
                } else {
                    const reason = caz_data.deny_reason ?? 'unexpected reason'
                    setFlashbar(setFlashbarCustomError(`Contingent authorization did not succeed for ${executionId} - reason: ${reason}`, setFlashbar));
                }
            } else {
                setFlashbar(flashbarReportAccessDenied);
            }
        }
	}

    async function generateDerMvpTicket(){
        setMvpConfirmationVisible(false);
        setFlashbar(setFlashbarCustomInfo("Generating IAM Policy DER for MVP partition"));
        const exec_id = selectedExecution?.execution_id! 
		const FondueApi = FondueApiFactory();
        await FondueApi.genMVP({ execution_id: exec_id, report_id: report_id })
            .then((response) => {
                if (response.data.ticket_link) {
                    setFlashbar(setFlashbarCustomSuccess(`Successfully generated IAM Policy DER SIM ticket for MVP partition: ${response.data.ticket_link!}`))
                }
            })
            .catch((_) => {
                setFlashbar(setFlashbarCustomError("Error: IAM Policy DER MVP SIM ticket generation failed"));    
            })
        
    }

    async function cancelReportExecution(FondueApi: FondueApi, cancelRequest:ReportExecution) {

        setRunReportModalButtonLoading(true)
        await getMidwayJwtToken();

        await FondueApi.cancelReportExecution(report_id, cancelRequest)
            .then((response) => {
                closeCancelModal();
                setRunReportModalButtonLoading(false)
            })
            .catch((e) => {
                setRunReportModalButtonLoading(false)
            })
    }

    function closeRunAgainModal(){
        // Close Modal
        setRunAgainModalVisible(false);
        // Hide all messages
        setShowErrorAlert(false)

        // Disabling all action buttons as selected Execution will be cleared
        setRunAgainDisabled(true);
        setDownloadDisabled(true);
        setCancelDisabled(true);
    }

    function runAgain(){
        const FondueApi = FondueApiFactory();
        setRunReportModalButtonLoading(true)

        const reportExecutionsRequest: ReportExecutionsRequest = {
            wbr_date_range: [selectedExecution!.wbr_date],
            backfill: false,
            selected_report_items: []
        }
        enqueueReportExecution(FondueApi, reportExecutionsRequest);
    }


    function closeCancelModal(){
        // Close Modal
        setCancelModalVisible(false);
        setCancelDisabled(true);
    }

    async function retrieveCloudfrontSignedUrl(FondueApi: FondueApi, retrieveReportRequest: RetrieveReportRequest) {
        await getMidwayJwtToken();
        await FondueApi.retrieveCloudFrontSignedUrl(report_id, retrieveReportRequest)
            .then((response) =>{
                setCloudFrontUrl(response.data.url!)
                setRetrievePdfDisabled(false);
            })
            .catch((e) => {
                if(e.response.status === 403){
                    setDownloadDisabled(true);
                    setGenReportDisabled(true);
                    setDownloadDatadumpDisabled(true);
                    setRetrievePdfDisabled(true);
                }
                setState(States.error);
            })
    }


    function retrieveLatestReportResult(fileType: string, download: boolean, exec_id = ""){
        const FondueApi = FondueApiFactory();
        if(latestExecution){
            const retrieveReportRequest: RetrieveReportRequest = {
                report_id: report_id,
                reportName: reportName,
                fileName: fileName,
                startDate: startDateValue,
                endDate: latestExecution!.wbr_date,
                fileType: fileType,
                executionId: latestExecution!.execution_id
            }
            retrieveReportFile(FondueApi, retrieveReportRequest, download);
            setViewResultDisabled(true);
        }
    }

    function viewResult(){
        setViewResultLoading(true);
        retrieveReportResult('pdf', false);
    }

    function retrieveReportResult(fileType: string, download: boolean, exec_id = "", displayed_end_date=""){
        const FondueApi = FondueApiFactory();

        // If no execution id passed in set exec_id in retrieve request to selected execution
        if(exec_id === ""){
            exec_id = selectedExecution!.execution_id || "";
        }

        let reportEndDate = (!!selectedExecution) && selectedExecution!.wbr_date || endDateValue;

        if(displayed_end_date != ""){
            reportEndDate = displayed_end_date;
        }


        const retrieveReportRequest: RetrieveReportRequest = {
            report_id: report_id,
            reportName: reportName,
            fileName: fileName,
            startDate: startDateValue,
            endDate: reportEndDate,
            fileType: fileType,
            executionId: exec_id
        }

        retrieveReportFile(FondueApi, retrieveReportRequest, download);
        setViewResultDisabled(false)
    }

    async function retrieveReportFile(FondueApi: FondueApi, retrieveReportRequest: RetrieveReportRequest, download: boolean) {
        await getMidwayJwtToken();
        await FondueApi.retrieveReportResult(report_id, retrieveReportRequest)
            .then((response) =>{
                // If download set to True
                if(download){
                    downloadReportS3(response.data.url!);
                }
                else{
                    setPdfUrl(response.data.url!);
                    setDisplayedExecutionID(retrieveReportRequest.executionId!)
                    setDisplayedExecutionEndDate(retrieveReportRequest.endDate!)
                    setDownloadaDisplayedDisabled(false);
                    setShowResultContainer(true);

                    setEndDateValue(retrieveReportRequest.endDate!)
                    setViewResultLoading(false);
                }
            })
            .catch((e) => {
                // For errors retrieving w/ download as False, replace PdfURL
                if(!download){
                    setPdfUrl("");
                    setDownloadaDisplayedDisabled(true);
                    setViewResultLoading(false);

                }
                // Unauthorized error
                if(e.response.status === 403){
                    setFlashbar(flashbarReportAccessDenied);
                }
                // File not found error
                else if(e.response.status === 404){
                    setFlashbar(flashbarFileNotFound);
                }
                else{
                    setFlashbar(flashbarReportRetrievingError);
                }
                setState(States.error);
            })
    }

    function retrieveCloudFrontSignedUrl(){
        const FondueApi = FondueApiFactory();

        const retrieveCFUrlRequest: RetrieveCFUrlRequest = {
            report_id: report_id
        }

        retrieveCloudfrontSignedUrl(FondueApi, retrieveCFUrlRequest);
    }

    function setStartDate(date){
        setStartDateValue(date)
        const startDate = new Date(date);
        const endDate = new Date(startDate.setDate(startDate.getDate() + 70));

        // Get endDate in yyyy-mm-dd format
        const endDateString = endDate.toISOString().split("T")[0];
        setEndDateValue(endDateString);
    }

    function setEndDate(date){
        setEndDateValue(date)
        const endDate = new Date(date);
        const startDate = new Date(endDate.setDate(endDate.getDate() - 70));

        // Get endDate in yyyy-mm-dd format
        const startDateString = startDate.toISOString().split("T")[0];
        setStartDateValue(startDateString);
    }

    function getPreviousSunday(date = new Date()) {
        const previousSunday = new Date();
        previousSunday.setDate(date.getDate() - date.getDay());
        return previousSunday;
    }

    async function setRecentWBRDate(){
        const date = new Date();
        const sunday = getPreviousSunday(date);

        const offset = sunday.getTimezoneOffset();
        const sundayOffsetDate = new Date(sunday.getTime() - (offset*60*1000));

        if (!IsDerReportTypeString(reportType)) {
            // Default to most recent wbr date
            setEndDate(sundayOffsetDate.toISOString().split("T")[0]);
        } else {
            // Default to 1 week for DER reports
            const todayOffset = date.getTimezoneOffset();
            const todayOffsetDate = new Date(date.getTime() - (todayOffset*60*1000));

            setStartDateValue(sundayOffsetDate.toISOString().split("T")[0]);
            setEndDateValue(todayOffsetDate.toISOString().split("T")[0]);
        }
    }

    function buttonAction(event){
        switch (event.detail.id) {
            case "dxlsx":
                retrieveReportResult('xlsx', true);
                break;
            case "dpdf":
                retrieveReportResult('pdf', true);
                break;
            case "djson":
                downloadDatadump("json");
                break;
            case "dcsv":
                downloadDatadump("csv");
                break;
            case "gmvp":
                setMvpConfirmationVisible(true);
                break;
            case "cancel":
                setCancelModalVisible(true);
                break;
            case "runagain":
                setRunAgainModalVisible(true);
                break;
            case "request_approval":
                setApprovalRequestModalVisible(true);
                break;
            case "obtain_caz":
                resumeExecutionWithCAZ(selectedExecution?.report_id!, selectedExecution?.execution_id!);
        }
    }


    let actionButtonItems;
    if (!IsDerReportTypeString(reportType)){
        actionButtonItems = [
            { text: "Download Excel", id: "dxlsx", disabled: downloadDisabled },
            { text: "Download PDF", id: "dpdf", disabled: downloadDisabled },
            { text: "Run again", id: "runagain", disabled: runAgainDisabled },
            { text: "Cancel", id: "cancel", disabled: cancelDisabled },
        ];
    } else {
        actionButtonItems = [
            {
                text: "Download Datadump",
                id: "ddatadumps",
                disabled: downloaddatadumpDisabled,
                items: [
                    { id: "djson", text: "JSON", disabled: downloaddatadumpDisabled},
                    { id: "dcsv", text: "CSV", disabled: downloaddatadumpDisabled}
                ]
            }, 
            { text: "Cancel", id: "cancel", disabled: cancelDisabled },
            { text: "Request MVP partition DER", id: "gmvp", disabled: generateMVPDisabled }
        ]
    }
    if (!appsecRequestModalButtonDisabled) {
        actionButtonItems.push({ text: "Request Approval", id: "request_approval", disabled: appsecRequestModalButtonDisabled })
    }
    if (!obtainCazDisabled) {
        actionButtonItems.push({ text: "Obtain Contigent Authorization", id: "obtain_caz", disabled: obtainCazDisabled });
    }


    const actionButtons = (
        <ButtonDropdown
            id ="btnDropdown"
            items={
                actionButtonItems
            }
            onItemClick={(event) => buttonAction(event)}
            expandableGroups
        >
            Actions
        </ButtonDropdown>
    )


    const [pageSize, setPageSize] = useState(20);

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        allExecutions || [],
        {
            filtering: {
                empty: (
                    <Box textAlign="center" color="inherit">
                        <b>No executions</b>
                        <Box
                            padding={{bottom: "s"}}
                            variant="p"
                            color="inherit"
                        >
                            No executions to display.
                        </Box>
                    </Box>
                ),
            },
            pagination: {
                pageSize: pageSize
            },
            sorting: {},
            selection: {}
        }
    );

    function handlePagination(event) {
        setSelectedItems([]);
        setSelectedExecution(undefined);
        setDownloadDisabled(true);
        setCancelDisabled(true);
        setRunAgainDisabled(true);
        setViewResultDisabled(true);
        setObtainCazDisabled(true);
        setAppsecRequestModalButtonDisabled(true);
    }


    function handleSelect(detail) {
        setSelectedItems(detail.selectedItems);

        setSelectedExecution(detail.selectedItems[0]);

        let status = detail.selectedItems[0].execution_status;

        setDownloadDisabled(true);
        setCancelDisabled(true);
        setRunAgainDisabled(false);
        setViewResultDisabled(true);
        setDownloadDatadumpDisabled(true);
        setGenerateMVPDisabled(true);
        setObtainCazDisabled(true);
        setAppsecRequestModalButtonDisabled(true);

        switch (status) {
            case "Complete":
                if (IsDerReportTypeString(reportType)) {
                    setDownloadDatadumpDisabled(false);
                    setGenerateMVPDisabled(false);
                }
                else {
                    setViewResultDisabled(false);
                    setDownloadDisabled(false);
                }
                break;
            case "Waiting_AppSec":
                setCancelDisabled(false);
                setRunAgainDisabled(true);
                setAppsecRequestModalButtonDisabled(false);
                break;
            case "Waiting_CAZ":
                setCancelDisabled(false);
                setRunAgainDisabled(true);
                setObtainCazDisabled(false);
                break;
            case "In_Progress" || "Pending":
                setCancelDisabled(false);
                setRunAgainDisabled(true);
                break;
        }
    }

    function statusToIndicator(status){
        var message = status
        switch (status) {
            case 'Complete':
                status = "success"
                break;
            case 'Failed':
                status = "error"
                break;
            case 'Pending':
                status = "pending"
                break;
            case 'In_Progress':
                message = "In Progress"
                status = "in-progress"
                break;
            case 'Canceled':
                status = "stopped"
                break;
            case 'Waiting_CAZ':
                message = "Pending Contingent Authorization"
                status = "pending"
                break;
            case 'Waiting_AppSec':
                message = "Pending Execution Approval"
                status = "pending"
                break;
        }
        return <StatusIndicator type={status}>{message}</StatusIndicator>
    }
    
    function linkGenerate(link) {
        return (link ? <Link external externalIconAriaLabel="Opens in a new tab" href={link}> Approvals {link.split('/').pop()} </Link>: '-')
    }

    /**
     * Submit callback task token to resume report execution step function. 
     * CAZ evaluation performed in backend and starts CAZ user workflow if required.  
     *
     * @param reportId - id of report
     * @param executionId - id of execution to resume
     * @param isRetry - used to make recursive call only once after obtaining CAZ 
     *                  should only be true if called within function 
    */
    async function resumeExecutionWithCAZ(reportId: string, executionId: string, isRetry=false){
        const FondueApi = FondueApiFactory();
        const submitTaskTokenRequest: SubmitTaskTokenRequest = {
            'report_id': reportId,
            'execution_id': executionId
        }
        if (!isRetry){
            setFlashbar(setFlashbarCustomInfo(`Obtaining contingent authorization for ${executionId}`, setFlashbar));
        }
        try { 
            await FondueApi.submitTaskToken(submitTaskTokenRequest);
            setState(States.success);
            setFlashbar(setFlashbarCustomSuccess(`Successfully obtained contingent authorization. Please refresh the table.`, setFlashbar));
            setObtainCazDisabled(true);
        } catch (error: any) {
            const response = error.response;
            // Only start CAZ workflow if it's the first attempt, otherwise will prompt after deny
            if (!isRetry && response.status == 403 && "caz" in response.data) {
                const caz_data = response.data.caz;
                if (caz_data.authorization_url != null) {
                    try {
                        await promptUserSimAnd2PR(caz_data.authorization_url);
                        // recursive call to try again
                        await resumeExecutionWithCAZ(reportId, executionId, true);
                    } catch {
                        setFlashbar(setFlashbarCustomError(`Failed to obtain contingent authorization for ${executionId}`,setFlashbar));
                    }
                } else {
                    const reason = caz_data.deny_reason ?? 'unexpected reason'
                    setFlashbar(setFlashbarCustomError(`Contingent authorization did not succeed for ${executionId} - reason: ${reason}`, setFlashbar));
                }
            } else {
                setFlashbar(setFlashbarCustomError(`Failed to resume execution for ${executionId}`,setFlashbar));
            }
        }
    }

    useEffect(() => {
        if(initialFlashbarMessage) {
            setFlashbar(setFlashbarCustomSuccess(initialFlashbarMessage));
            setData({});
        }
    }, [initialFlashbarMessage]);

    useEffect(() => {
        if(reportName != ''){
            setRecentWBRDate()
            if(cloudFrontUrl === ""){
                retrieveCloudFrontSignedUrl();
            }
            if(IsDerReportTypeString(reportType)) {
                setStartDateDisabled(true);
                setEndDateDisabled(true);
                setGenReportDisabled(false);
                setDownloadDisabled(true);
            }
            retrieveReportExecutions();
        }
    }, [reportName, setAllExecutions, runReportModalButtonLoading]);

    useEffect(() => {
        // Refresh executions every 15 seconds if any in progress
        let intervalId;
        if (allExecutions && allExecutions.some(execution => execution.execution_status === 'In_Progress')) {
            intervalId = setInterval(() => {
                retrieveReportExecutions(); 
            }, 15000);
        }
        return () => clearInterval(intervalId);
    }, [allExecutions]);

    useEffect(() => {
        // Load and set latest execution for WBR Reports
        // This will load PDF by default if latest execution is complete, else show an alert
        if(allExecutions && !IsDerReportTypeString(reportType)){
            for (let i=0; i < allExecutions!.length; i++){

                var exec_status = allExecutions[i].execution_status;
                if(exec_status === 'Complete') {
                    setLatestExecution(allExecutions[i]);
                    setShowResultContainer(true);
                    break;
                }
                if(exec_status === 'Failed'){
                    setShowLatestReportError(true);
                    setShowResultContainer(false);
                    break;
                }
            }

        }

    }, [reportName, reportType, allExecutions, setAllExecutions]);

    useEffect(() => {
        if(cloudFrontUrl != ""){
            retrieveLatestReportResult('pdf', false);
            setLoadPdf(true);
        }
    },[latestExecution, setLatestExecution, cloudFrontUrl, setCloudFrontUrl])

    useEffect(() => {
    },[loadPdf, setLoadPdf]);

    return (
        <SpaceBetween direction="vertical" size="xs">
            {showLatestReportError &&
            <Alert type="error" header="Report failed">
                The most recent execution of this report failed. You can run the report again or view results for a previous execution.
            </Alert>
            }
            {showResultContainer &&
            <Container
                header={
                    <Header
                        variant="h2"
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button id="dwnxlsx" variant="normal" iconName="download" disabled={downloadDisplayedDisabled}
                                        onClick={() => retrieveReportResult('xlsx', true, displayedExecutionID, displayedExecutionEndDate)}>
                                    Download as XLSX
                                </Button>
                            </SpaceBetween>
                        }
                    >
                        {"Latest File | " + startDateValue + " to " + endDateValue}
                    </Header>
                }
            >
                <SpaceBetween direction="vertical" size="xl">
                    <Box variant="h2">
                        {(pdfUrl == "" && cloudFrontUrl != "") && <Spinner/> }
                        {(pdfUrl != "" && cloudFrontUrl != "" && loadPdf) &&
                        <iframe style={{border: "none", width: "100%"}} height={"600px"} src={pdfUrl}/>}
                    </Box>
                </SpaceBetween>
            </Container>}


            <SpaceBetween direction="vertical" size="xl">
            </SpaceBetween>
            <Modal
                onDismiss={closeRunReportModal}
                visible={runReportModalVisible}
                closeAriaLabel="Close dialog box"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="normal" onClick={closeRunReportModal}>Close</Button>
                            <Button variant="primary" id="run-report-md" onClick={() =>  enqueueDatadumpExecution()}
                                    loading={runReportModalButtonLoading}>Run Report</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Run Report"
            >
                    <SpaceBetween direction="vertical" size="l">
                        <div>
                            {
                                showErrorAlert ? (
                                    <Alert type="error">
                                        Cannot run report. There is an existing pending or running execution for the
                                        same date range.
                                        To run the report, cancel the overlapping execution and try again or choose a
                                        different date range to run the report for.
                                    </Alert>
                                ) : ('')
                            }
                        </div>
                        <div>
                            Run this report for {Util.dateToYMDSlashString(DateUtil.getTodayDate())}?
                        </div>
                    </SpaceBetween>
            </Modal>

            <Modal
                onDismiss={closeCancelModal}
                visible={cancelModalVisible}
                id='cancel-modal'
                closeAriaLabel="Close dialog box"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="normal" onClick={closeCancelModal}>Back</Button>
                            <Button variant="primary" id="cancel-md" onClick={cancelExecution}
                                    loading={runReportModalButtonLoading}>Cancel execution</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Cancel execution"
            >
                Are you sure you want to cancel this execution?
            </Modal>
            <Modal
                onDismiss={closeRunAgainModal}
                visible={runAgainModalVisible}
                id="run-again-modal"
                closeAriaLabel="Close dialog box"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="normal" id="cancel-run-ag" onClick={closeRunAgainModal}>Cancel</Button>
                            <Button variant="primary" id="run-ag-md" onClick={runAgain}
                                    loading={runReportModalButtonLoading}>Run again</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Run this execution again?"
            >
                <SpaceBetween direction="vertical" size="l">

                    <div>
                        {
                            showErrorAlert ? (
                                <Alert type="error">
                                    Cannot run report. There is an existing pending or running execution for the
                                    same date range.
                                    To run the report, cancel the overlapping execution and try again or choose a
                                    different date range to run the report for.
                                </Alert>
                            ) : ('')
                        }

                    </div>
                    <div>
                        Are you sure you want to run this execution again? It will override currently stored data and regenerate the report for the previous 10 weeks.
                    </div>
                </SpaceBetween>
            </Modal>
            {approvalRequestModalVisible && <ApprovalRequestForm reportName={reportName} reportId={report_id} executionId={selectedExecution!.execution_id || ""} approvalRequestModalVisibleState={[approvalRequestModalVisible, setApprovalRequestModalVisible]} />}
            <Confirmation visible={mvpConfirmationVisible} header="IAM Policy DER Request" description="Do you want generate an IAM Policy DER SIM ticket for MVP partition?" onAccept={generateDerMvpTicket} onCancel={() => setMvpConfirmationVisible(false)}/>
            <Table
                {...collectionProps}
                selectionType="single"
                loading={!allExecutions || refreshTable}
                onSelectionChange={({ detail }) =>
                    handleSelect(detail)
                }
                selectedItems={selectedItems}
                header={<Header
                    counter ={
                        allExecutions &&
                        (selectedItems!.length ? `(1/${allExecutions.length})` : `(${allExecutions.length})`)

                    }
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="normal" id="refresh" loading={refreshTable} iconName="refresh" onClick={() => retrieveReportExecutions()}></Button>

                            <Button variant="normal" id="view-result" disabled={viewResultDisabled}
                                    loading={viewResultLoading}
                                    onClick={() => viewResult()}>View Result</Button>                        
                            {actionButtons}
                            <Button variant="primary" id="run-report" iconName="caret-right-filled" disabled={reportName === ""}
                                    onClick={() =>  IsDerReportTypeString(reportType) ? setRunReportModalVisible(true): history.push(`/reports/${report_id}/runreport`)}>Run Report</Button>
                        </SpaceBetween>
                    }

                >
                    Run history </Header>
                }
                columnDefinitions={[
                    {
                        id: "wbr_date",
                        header: "Report date",
                        cell: item => item.wbr_date || "-",
                        sortingField: "wbr_date"
                    },
                    {
                        id: "end_datetime",
                        header: "Completed",
                        cell: item => item.end_datetime || "-",
                        sortingField: "end_datetime"
                    },
                    {
                        id: "execution_id",
                        header: "Run ID",
                        cell: item => item.execution_id || "-",
                        sortingField: "execution_id"
                    },
                    {
                        id: "execution_status",
                        header: "Status",
                        cell: item => (statusToIndicator(item.execution_status)),
                        sortingField: "execution_status"
                    },
                    {
                        id: "items_included",
                        header: "Included items",
                        cell: item => item.items_included || "All report items",
                        sortingField: "items_included"
                    },
                    {
                        id: "app_sec_status",
                        header: "AppSec Status",
                        cell: item => item.app_sec_status || "-",
                        sortingField: "app_sec_status"
                    },
                    {
                        id: "app_sec_link",
                        header: "AppSec Ticket",
                        cell: item => (linkGenerate(item.app_sec_link)),
                        sortingField: "app_sec_link"
                    }
                ]}
                columnDisplay={visibleColumns}
                items={items}
                loadingText="Loading past executions"
                pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}
                                        onChange={ event  => {
                                            handlePagination(event)
                                            paginationProps.onChange(event);
                                        }}
                />}
                filter={
                    <TextFilter
                        {...filterProps}
                        countText={getMatchesCountText(filteredItemsCount!)}
                        filteringAriaLabel="Filter records"
                    />
                }
                preferences={
                    <CollectionPreferences
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        preferences={{
                            pageSize: pageSize,
                        }}
                        pageSizePreference={{
                            title: "Select page size",
                            options: [
                                { value: 20, label: "20 executions" },
                                { value: 50, label: "50 executions" },
                                { value: 100, label: "100 executions" }
                            ]
                        }}
                        onConfirm={(event) => setPageSize(event.detail.pageSize as number)}
                    />
                }
            />
            {(cloudFrontUrl != "")  && <iframe style={{border:"none", width: "100%"}} height={"0.1px"} src={cloudFrontUrl}/>}

        </SpaceBetween>
    );
}